<template>
  <div class="pb-1">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="name"
              v-model="filters.name"
              :label="$t('Nome da marca')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="brand-manufacturer-id"
              v-model="filters.manufacturerId"
              type="vue-select"
              :label="$t('Fabricante')"
              :options="getComboManufactures"
            /> </b-col></b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="brands-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('BRAND.NAME'), 'female')"
        :fields="fields"
        :items="brands"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(image)="row">
          <b-img-lazy
            :src="row.item.image || noImage"
            rounded
            center
            width="165px"
            style="object-fit: contain; max-height: 150px"
          />
        </template>

        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'Brand')"
            :show-delete="$can('Delete', 'Brand')"
            :show-activate="$can('Activate', 'Brand')"
            :show-deactivate="$can('Deactivate', 'Brand')"
            @update="showUpdateBrandSidebar(row)"
            @delete="deleteBrand(row)"
            @activate="activateDeactivateBrand(row)"
            @deactivate="activateDeactivateBrand(row)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <brand-sidebar
      ref="brand_sidebar"
      @save="getData"
    />

    <fab
      v-if="$can('Create', 'Brand')"
      :main-tooltip="$t('Adicionar Marca')"
      @click="showCreateBrandSidebar"
    />
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable, BImgLazy } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStatusBadge,
  EFilters,
  EGridActions,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { uploader } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import BrandSidebar from './BrandSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    // BDropdown,
    // BDropdownItem,
    BTable,
    BImgLazy,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EFilters,
    BrandSidebar,
    EGridActions,
  },

  mixins: [uploader],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      imageLocal: [],
      // eslint-disable-next-line global-require
      noImage: require('@/assets/images/noimage.png'),
    }
  },

  computed: {
    ...mapState('pages/catalog/brands', {
      brands: 'brands',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),
    ...mapGetters('pages/catalog/products', ['getComboManufactures']),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Id API'),
          key: 'apiId',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Imagem'),
          key: 'image',
          class: 'text-center',
          thStyle: { width: '200px' },
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          trClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '90px' },
          sortable: true,
        },
      ]
    },
  },

  watch: {
    brand(val) {
      this.imageLocal = val?.image ? [this.getFileInstance(val.image)] : []
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),
    ...mapActions('pages/catalog/brands', [
      'fetchBrands',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchBrands()
          await this.comboFetchDomainData('manufacturers')
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreateBrandSidebar() {
      this.$refs.brand_sidebar.show()
    },

    async showUpdateBrandSidebar(row) {
      this.$refs.brand_sidebar.show({ id: row.item.id })
    },

    async deleteBrand(brand) {
      const { id } = brand.item
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http.delete(`/api/brands/${id}`)
          this.comboFetchDomainData('brands')
          this.getData()
          this.showSuccess({ message: this.$t('Marca removida com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async activateDeactivateBrand(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/brands/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.comboFetchDomainData('brands')
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
